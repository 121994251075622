angular.module('app')
    .factory('timezoneService', ["$http", function ($http) {

        var httpConfig = {
            headers: {'Pragma': undefined, 'Cache-Control': undefined, 'X-Requested-With': undefined, 'If-Modified-Since': undefined}
        };

        /**
         * Get the timezone of a location using Google Timezone API.
         *
         * @param locality the latitude and longitude of the pickup location
         * @param pickupDateTime the date/time of the requested pickup (used to determine if DST offset should be applied).
         * @returns {*} an HTTP promise.
         */
        function timezone(locality, pickupDateTime) {
            return $http.get('https://maps.googleapis.com/maps/api/timezone/json?key=AIzaSyACKMU_fD7km9o3Mdw_bmk9rTLq2y2LisU&location=' +
                locality.latitude + ',' + locality.longitude + '&timestamp=' + moment(pickupDateTime).valueOf() / 1000, httpConfig);
        }

        return {
            timezone: timezone
        };
    }]);
